/**
 * @name: 报损管理-报损记录接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 报损管理-报损记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {frmlossRecordParams} from "@/apis/damaged/list/types";
/**
 * 查询报损记录列表
 * @param params 查询参数
 * @returns
 */
export const frmlossRecordQueryApi = (params: frmlossRecordParams) => get("/admin/frmlossRecord/queryByPage", params)

/**
 * 查询报损记录导出
 * @param params 查询参数
 * @returns
 */
export const frmlossRecordExportApi = (params: frmlossRecordParams) => get("/admin/frmlossRecord/frmlossRecordExport", params,"blob")
