
/**
 * @name: 报损管理-报损记录
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 报损管理-报损记录
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import type { ICrudOption } from "@/types/m-ui-crud"
import {IOrderParams} from "@/apis/purchase/order/types";
import {deepCopy, exportFile} from "@/utils/common";
import {frmlossRecordParams} from "@/apis/damaged/list/types";
import {frmlossRecordExportApi, frmlossRecordQueryApi} from "@/apis/damaged/list";
import {IOrderDetailsParams} from "@/apis/purchase/list/types";
import {orderDetailsExportApi} from "@/apis/purchase/list";
import {gateGetGateListApi} from "@/apis/gate/info";

@Component({})
export default class damagedList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: frmlossRecordParams = {
    page: 1,
    limit: 10
  }
  frmlossPriceTotal:string='0.00'
  gateList:any=[]
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox:true,
    menu:false,
    column: [
      {
        label:'报损记录ID',
        prop:'id',
        align:'left',
        width:180
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        align: "center",
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' }),
        type:'select',
        hide:true,
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
      },
      {
        label: "商品名称",
        prop: "productName",
        search:true,
        align: "center",
      },
      {
        label: "报损数量",
        prop: "frmlossQtyUnit",
        // slot:true,
        align: "center",
      },
      {
        label: "报损价",
        prop: "priceUnit",
        // slot:true,
        align: "center",
      },
      {
        label: "报损金额",
        prop: "frmlossPrice",
        align: "center",
      },
      {
        label: "批次号",
        prop: "batchNos",
        width:180,
        align: "center",
      },
      {
        label: "经办人",
        prop: "handlerName",
        align: "center",
      },
      {
        label: "报损时间",
        prop: "frmlossTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        addHide: true,
        editHide: true,
        span: 12
      },
    ]
  }
  /**
   * 获取档口下拉列表
   */
  getGateList() {
    gateGetGateListApi().then(e => {
      this.gateList = e.map((item: any) => {
        return {
          label: item.gateName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("gateId", {
        dicData: this.gateList
      })
    })
  }
  /**
   * 导出excel
   */
  exportExcel() {
    const form: frmlossRecordParams = deepCopy(this.queryParam);
    if (this.queryParam.frmlossTime && this.queryParam.frmlossTime.length) {
      form.frmlossTimeStart = this.queryParam.frmlossTime[0]
      form.frmlossTimeEnd = this.queryParam.frmlossTime[1]
    } else {
      form.frmlossTimeStart = ""
      form.frmlossTimeEnd = ""
    }
    delete form.frmlossTime
    frmlossRecordExportApi(form).then(e => {
      exportFile(e, '报损记录.xlsx')
    })
  }

  getList () {
    const form: frmlossRecordParams = deepCopy(this.queryParam);
    if (this.queryParam.frmlossTime && this.queryParam.frmlossTime.length) {
      form.frmlossTimeStart = this.queryParam.frmlossTime[0]
      form.frmlossTimeEnd = this.queryParam.frmlossTime[1]
    } else {
      form.frmlossTimeStart = ""
      form.frmlossTimeEnd = ""
    }
    delete form.frmlossTime
    form.gateIdList=form.gateIdList?.toString()
    frmlossRecordQueryApi(form).then(e => {
      this.frmlossPriceTotal = e.frmlossPriceTotal || 0;
      let {frmlossRecordVoPage} = e;
      //@ts-ignore
      this.tableData = frmlossRecordVoPage.records;
      this.tableTotal = frmlossRecordVoPage.total
    })
  }
  created () {
    this.getGateList();
    this.getList()
  }
}
